import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { AuthErrorMessage, InputField, WideButton } from '../../components';
import { isEmail } from '../../utils';
import { useLocation, useNavigate } from 'react-router-dom';
import apis from '../../repositories/api';
import { frontendRoutes, ResponseCode, SHOW_LINK_SENT_POPUP } from '../../config';
import { logUserAction } from '../../services';
import { UserActions } from '../../models/Logs';

export const ResetPasswordRequest = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const [email, setEmail] = useState<string>(state?.email);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const navigate = useNavigate();

  useEffect(() => {
    if (email?.trim()) {
      setErrorMessage('');
    }
  }, [email]);

  const onSendRequest = async () => {
    logUserAction({
      message: UserActions.button,
      context: {
        button: 'change-password-request',
        email,
      },
    });

    const validEmail = await isEmail(email);

    if (!validEmail) {
      setErrorMessage(t('common.notice.invalid_email_msg'));
      return;
    }

    const {
      data: { status },
    } = await apis.resetPasswordRequest(email);
    if (status === ResponseCode.success) {
      localStorage.setItem(SHOW_LINK_SENT_POPUP, 'true');
      navigate(frontendRoutes.login, { state: { email } });
    } else if (status === ResponseCode.networkError) {
      setErrorMessage(t('common.notice.servers_unavailable'));
      return;
    } else {
      setErrorMessage(t('login_signup.notice.no_matching_email'));
    }
  };

  return (
    <>
      <Typography component='h3' variant='h3regular' textAlign='center'
                  paddingTop={5}>
        {t('login_signup.reset_password.header')}
      </Typography>
      <Stack width='100%' gap={3}>
        <InputField
          id='signup-email-phone'
          label={t('common.field_label.email')}
          value={email}
          error={Boolean(errorMessage)}
          onChange={setEmail}
        />
        <AuthErrorMessage
          message={errorMessage}
          visible={Boolean(errorMessage)}
        />
      </Stack>
      {/* <Typography variant="bodySmall" textAlign="center">
        {t('changePasswordRequest')}
      </Typography> */}
      <Stack flex={1} width='100%' gap={2}>
        <WideButton
          disabled={!email || Boolean(errorMessage)}
          message={t('common.button.submit')}
          onClick={onSendRequest}
        />
      </Stack>
    </>
  );
};
