export const SendToPhoneIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="50" height="80" viewBox="0 0 50 80" fill="none">
    <g clipPath="url(#clip0_1_37413)">
      <path
        d="M19.8345 50.0073L34.8345 35.0073L19.8345 20.0073V30.0073H-0.165527V40.0073H19.8345V50.0073ZM39.8345 5.00732H19.8345C14.3345 5.00732 9.83447 9.50732 9.83447 15.0073V25.0073H14.8345V15.0073H44.8345V60.0073H14.8345V45.0073H9.83447V65.0073C9.83447 70.5073 14.3345 75.0073 19.8345 75.0073H39.8345C45.3345 75.0073 49.8345 70.5073 49.8345 65.0073V15.0073C49.8345 9.50732 45.3345 5.00732 39.8345 5.00732ZM32.3345 70.0073H27.3345C25.8345 70.0073 24.8345 69.0073 24.8345 67.5073C24.8345 66.0073 25.8345 65.0073 27.3345 65.0073H32.3345C33.8345 65.0073 34.8345 66.0073 34.8345 67.5073C34.8345 69.0073 33.8345 70.0073 32.3345 70.0073Z"
        fill="#0096DB"
      />
    </g>
    <defs>
      <clipPath id="clip0_1_37413">
        <rect width="50" height="80" fill="white" transform="translate(0 0.00244141)" />
      </clipPath>
    </defs>
  </svg>
);
