export const AppleIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="55" height="68" viewBox="0 0 55 68" fill="none">
    <path
      d="M51.5259 25.2613L52.2517 24.6835L51.73 23.9165C47.294 17.3942 40.5433 17.2286 38.724 17.2286C36.2142 17.2286 33.9649 18.016 32.1191 18.7482C31.8074 18.8719 31.5126 18.9918 31.2308 19.1063C30.6527 19.3413 30.1295 19.554 29.6284 19.7313C28.8845 19.9945 28.3257 20.1269 27.8961 20.1269C27.425 20.1269 26.86 19.9983 26.1581 19.7549C25.6207 19.5685 25.0609 19.3366 24.4422 19.0803C24.2492 19.0004 24.0504 18.9181 23.8448 18.834C22.1657 18.1475 20.1459 17.3924 17.9035 17.3924C9.63192 17.3924 1.5 24.4185 1.5 37.1368C1.5 44.9893 4.4376 53.2302 8.09328 58.5636L8.09693 58.5688C9.61973 60.7603 11.1081 62.8471 12.7217 64.3784C14.3521 65.9257 16.2077 67 18.4686 67C20.4936 67 21.9948 66.3455 23.377 65.7429C23.4623 65.7058 23.5471 65.6688 23.6315 65.6322C25.0801 65.0042 26.5186 64.4293 28.6577 64.4293C30.8579 64.4293 32.1782 64.9841 33.5281 65.5868C33.584 65.6118 33.6402 65.637 33.6965 65.6622C35.0411 66.2643 36.5009 66.9181 38.7302 66.9181C41.1627 66.9181 43.1267 65.7793 44.773 64.222C46.4021 62.681 47.8034 60.6498 49.1086 58.7228L49.112 58.7177C50.6322 56.4434 51.7072 54.196 52.4069 52.4936C52.7573 51.6411 53.0152 50.9215 53.1888 50.3982C53.3517 49.9075 53.4597 49.5323 53.4837 49.4012L53.6386 48.5539L52.8236 48.2752L52.8233 48.2751C52.8004 48.2658 50.8881 47.4889 48.9918 45.6157C47.1101 43.7568 45.2578 40.8364 45.2578 36.4815C45.2578 29.5471 50.2003 26.1708 51.3282 25.4004C51.4224 25.336 51.49 25.2898 51.5259 25.2613ZM40.653 2.78655L40.4742 1.96851L39.6375 2.00074C36.2019 2.13308 32.2571 4.30174 29.8792 7.12869C28.0226 9.29547 26.2574 12.7417 26.2574 16.3132C26.2574 16.8879 26.3476 17.4559 26.4004 17.6814L26.5482 18.3131L27.1852 18.4356C27.4247 18.4817 27.8096 18.5356 28.2094 18.5356C29.8159 18.5356 31.554 17.99 33.1323 17.135C34.7143 16.278 36.2048 15.0733 37.3109 13.6698C39.343 11.1926 40.7927 7.75158 40.7927 4.26643C40.7927 3.77427 40.753 3.24429 40.653 2.78655Z"
      fill="#252525"
      stroke="white"
      strokeWidth="2"
    />
  </svg>
);
