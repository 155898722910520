export const AndroidIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="58" height="67" viewBox="0 0 58 67" fill="none">
    <path
      d="M28.8514 37.9852C38.3752 37.9852 46.0958 30.8477 46.0958 22.043C46.0958 13.2384 38.3752 6.10083 28.8514 6.10083C19.3275 6.10083 11.6069 13.2384 11.6069 22.043C11.6069 30.8477 19.3275 37.9852 28.8514 37.9852Z"
      fill="#A4C639"
      stroke="white"
      strokeWidth="4"
    />
    <path
      d="M41.9268 18.0574H15.7759C13.4735 18.0574 11.6069 19.9267 11.6069 22.2327V48.4235C11.6069 50.7295 13.4735 52.5988 15.7759 52.5988H41.9268C44.2293 52.5988 46.0958 50.7295 46.0958 48.4235V22.2327C46.0958 19.9267 44.2293 18.0574 41.9268 18.0574Z"
      fill="#A4C639"
      stroke="white"
      strokeWidth="2"
    />
    <path
      d="M16.4167 3.91354C15.8866 2.95574 16.2318 1.74792 17.1894 1.21629C18.147 0.684655 19.3527 1.03154 19.8828 1.98933L26.5894 14.1068C27.1195 15.0646 26.7743 16.2724 25.8167 16.804C24.859 17.3357 23.6534 16.9888 23.1233 16.031L16.4167 3.91354Z"
      fill="#A4C639"
      stroke="white"
      strokeWidth="1.5"
    />
    <path
      d="M46.8537 26.5979C46.8537 24.0823 48.8899 22.043 51.4017 22.043C53.9135 22.043 55.9497 24.0823 55.9497 26.5979V42.7299C55.9497 45.2455 53.9135 47.2848 51.4017 47.2848C48.8899 47.2848 46.8537 45.2455 46.8537 42.7299V26.5979Z"
      fill="#A4C639"
      stroke="white"
      strokeWidth="2"
    />
    <path
      d="M30.7463 45.0073C30.7463 42.4917 32.7825 40.4524 35.2943 40.4524C37.8061 40.4524 39.8423 42.4917 39.8423 45.0073V61.1393C39.8423 63.6549 37.8061 65.6942 35.2943 65.6942C32.7825 65.6942 30.7463 63.6549 30.7463 61.1393V45.0073Z"
      fill="#A4C639"
      stroke="white"
      strokeWidth="2"
    />
    <path
      d="M41.2859 3.91354C41.816 2.95574 41.4709 1.74792 40.5132 1.21629C39.5556 0.684655 38.35 1.03154 37.8199 1.98933L31.1133 14.1068C30.5832 15.0646 30.9283 16.2724 31.886 16.804C32.8436 17.3357 34.0492 16.9888 34.5793 16.031L41.2859 3.91354Z"
      fill="#A4C639"
      stroke="white"
      strokeWidth="1.5"
    />
    <path
      d="M10.8489 26.5979C10.8489 24.0823 8.8127 22.043 6.30092 22.043C3.78913 22.043 1.75293 24.0823 1.75293 26.5979V42.7299C1.75293 45.2455 3.78913 47.2848 6.30092 47.2848C8.8127 47.2848 10.8489 45.2455 10.8489 42.7299V26.5979Z"
      fill="#A4C639"
      stroke="white"
      strokeWidth="2"
    />
    <path
      d="M26.9563 45.0073C26.9563 42.4917 24.9201 40.4524 22.4083 40.4524C19.8966 40.4524 17.8604 42.4917 17.8604 45.0073V61.1393C17.8604 63.6549 19.8966 65.6942 22.4083 65.6942C24.9201 65.6942 26.9563 63.6549 26.9563 61.1393V45.0073Z"
      fill="#A4C639"
      stroke="white"
      strokeWidth="2"
    />
    <path
      d="M28.8514 37.9852C38.3752 37.9852 46.0958 30.8477 46.0958 22.043C46.0958 13.2384 38.3752 6.10083 28.8514 6.10083C19.3275 6.10083 11.6069 13.2384 11.6069 22.043C11.6069 30.8477 19.3275 37.9852 28.8514 37.9852Z"
      fill="#A4C639"
    />
    <path
      d="M41.9268 18.0574H15.7759C13.4735 18.0574 11.6069 19.9267 11.6069 22.2327V48.4235C11.6069 50.7295 13.4735 52.5988 15.7759 52.5988H41.9268C44.2293 52.5988 46.0958 50.7295 46.0958 48.4235V22.2327C46.0958 19.9267 44.2293 18.0574 41.9268 18.0574Z"
      fill="#A4C639"
    />
    <path d="M10.8491 22.7073H46.854H10.8491Z" fill="white" />
    <path d="M10.8491 22.7073H46.854" stroke="white" />
    <path
      d="M20.8928 15.0207C21.3114 15.0207 21.6508 14.6809 21.6508 14.2616C21.6508 13.8423 21.3114 13.5024 20.8928 13.5024C20.4741 13.5024 20.1348 13.8423 20.1348 14.2616C20.1348 14.6809 20.4741 15.0207 20.8928 15.0207Z"
      fill="white"
      stroke="white"
      strokeWidth="2"
    />
    <path
      d="M36.8107 15.0207C37.2294 15.0207 37.5687 14.6809 37.5687 14.2616C37.5687 13.8423 37.2294 13.5024 36.8107 13.5024C36.3921 13.5024 36.0527 13.8423 36.0527 14.2616C36.0527 14.6809 36.3921 15.0207 36.8107 15.0207Z"
      fill="white"
      stroke="white"
      strokeWidth="2"
    />
  </svg>
);
